import { Home } from "./pages/home2";
import { PrivacyPage } from "./pages/privacy";
import { TermsPage } from "./pages/terms";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { DisclaimerPage } from "./pages/disclaimer";
import { LegalPage } from "./pages/legal";
import { AboutUsPage } from "./pages/about";
import { CareersPage } from "./pages/careers";
import { ServicesPage } from "./pages/services";
import { Container } from "react-bootstrap";
// import { NftPage } from './pages/nft2';

import { Header } from "./components/header2";
import { Footer } from "./components/footer2";

export default function Hello() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/privacy">
          <Container>
            <PrivacyPage />
          </Container>
        </Route>
        <Route path="/terms">
          <Container>
            <TermsPage />
          </Container>
        </Route>
        <Route path="/disclaimers">
          <Container>
            <DisclaimerPage />
          </Container>
        </Route>
        <Route path="/legal">
          <Container>
            <LegalPage />
          </Container>
        </Route>
        {/* <Route path="/about">
          <AboutUsPage />
        </Route>
        <Route path="/services">
          <ServicesPage />
        </Route> */}
        {/* <Route path="/nft">
            <NftPage />
          </Route> */}
        {/* <Route path="/careers">
            <CareersPage />
          </Route> */}
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Container>
        <Footer />
      </Container>
    </Router>
  );
}
