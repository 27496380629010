import React from 'react';
// TODO: Remove StyledLink
import { StyledLink } from 'baseui/link';
import { Row, Col } from 'react-bootstrap';

export const LegalPage = () => {
  return (
    <Row>
      <Col>
      <h1>Legal</h1>
      <ul>
        <li>
          <StyledLink href="/privacy">Privacy Policy</StyledLink>
        </li>
        <li>
          <StyledLink>Cookie Policy</StyledLink>
        </li>
        <li>
          <StyledLink href="/terms">Terms and Conditions</StyledLink>
        </li>
        <li>
          <StyledLink href="/disclaimers">Disclaimers</StyledLink>
        </li>
      </ul>
      </Col>
    </Row>
  );
};
