import React from "react";
import { ALIGNMENT } from "baseui/layout-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faRocket,
  faLaptopCode,
  faUsers,
  faShieldAlt,
  faFighterJet,
  faHome,
  faQuestion,
  faPuzzlePiece,
  faFileAlt,
  faQuestionCircle,
  faTachometerAlt,
  faCertificate,
  faComments,
  faRobot,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Card, Container } from "react-bootstrap";

import styles from "./home.module.scss";

export const Home = () => {
  return (
    <>
      <Container>
        <Col align={ALIGNMENT.center}>
          <Row>
            <Col xs="12">
              <h1 align={ALIGNMENT.center}>
                From Idea to Launch: Custom Software Development, Fractional CTO
                Services, and Elite Candidate Screening.
              </h1>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <h3 align={ALIGNMENT.center} className={styles.mainDescriptor}>
                At WaWe, we empower organizations of all scales. Our blend of
                bespoke software solutions, tailored tech advisory, and rigorous
                talent screening is the catalyst for your next leap forward.
                Ready for unparalleled growth?
              </h3>
            </Col>
          </Row>
          <Row style={{ padding: "2em" }}>
            <Col align={ALIGNMENT.center}>
              <Button
                className="black-btns"
                onClick={() => {
                  window
                    .open(
                      "mailto:webleads@teamwawe.com?subject=Help me grow my business!",
                      "_blank"
                    )
                    .focus();
                }}
              >
                Grow my business!
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
      <Col style={{ paddingBottom: "1em" }}>
        <Row>
          <Col style={{ backgroundColor: "rgb(45,45,45)" }}>
            <Row style={{ padding: "1em", textAlign: "center" }}>
              <h2 style={{ color: "white" }}>Some of our past clients</h2>
            </Row>
            <div align={ALIGNMENT.center} className={styles.pastClients}>
              <div style={{ paddingBottom: "1em" }}>
                <img src="/assets/veefriends-logo.svg" />
              </div>
              <div style={{ paddingBottom: "1em" }}>
                <img src="/assets/mait-logo.svg" />
              </div>
              <div style={{ paddingBottom: "1em" }}>
                <img src="/assets/wave-logo.svg" />
              </div>
            </div>
            <Row align={ALIGNMENT.center}>
              <Col>
                <img
                  src="/assets/one-capitol-mngmt-logo.webp"
                  width="50%"
                  className={styles.oneCapitalLogo}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.imageAndTextContainer}>
          <div className={styles.imageContainer}>
            <img src="/assets/stock-img1-keyboard.webp" />
          </div>
          <div className={styles.text}>
            We are renowned for our methodical approach to software development,
            eliminating uncertainties and ensuring robust, scalable solutions
            tailored for business growth. Schedule a consultation today if
            you're ready to transform your operations in the next 90 days.
            Discover firsthand how we've empowered businesses with tech
            solutions that drive results!
          </div>
        </div>
        <Row>
          <Col
            style={{ backgroundColor: "black", padding: "2em" }}
            align={ALIGNMENT.center}
          >
            <Row style={{ paddingBottom: "1em" }}>
              <Col>
                <h2 style={{ color: "white" }}>Software Guidance Services</h2>
              </Col>
            </Row>
            <div className={styles.serviceCardContainer}>
              <div>
                <Card className={styles.card}>
                  <div align={ALIGNMENT.center}>
                    <FontAwesomeIcon icon={faLaptopCode} size={"3x"} />
                    <br />
                    <h2>Custom Software Development</h2>
                    <p>
                      Tight timelines or just need more capacity. Our engineers
                      have the skills to jump right in with little to no
                      on-boarding. No need to spend time ramping WaWe engineers
                      up on tech and SDLC; they are just that good and don't
                      sacrifice quality!
                    </p>
                  </div>
                </Card>
              </div>
              <div>
                <Card className={styles.card}>
                  <div align={ALIGNMENT.center}>
                    <FontAwesomeIcon icon={faUsers} size={"3x"} />
                    <br />
                    <h2>Team Building</h2>
                    <p>
                      Building and retaining high executing teams is tough. We
                      offer advice and services to acquiring new talent, like
                      candidate screening and interview process refinement. Once
                      you have great talent hired you want to make sure you
                      retain those individuals that propel your team to the next
                      level. WaWe advises clients on how to keep their
                      compensation packages competitive with the market, as well
                      as, ensure your team's health is at sustainable levels.
                    </p>
                  </div>
                </Card>
              </div>
              <div>
                <Card className={styles.card}>
                  <div align={ALIGNMENT.center}>
                    <FontAwesomeIcon icon={faRocket} size={"3x"} />
                    <br />
                    <h2>Fractional CTO</h2>
                    <p>
                      Leverage the expertise of a Chief Technology Officer
                      without the full-time commitment. Our Fractional CTO
                      service provides your business with strategic tech
                      leadership, guiding software development, infrastructure
                      planning, and team management. Get the insights and
                      direction of a seasoned tech executive, scaled to fit your
                      needs and budget.
                    </p>
                  </div>
                </Card>
              </div>
            </div>
            <Row style={{ padding: "2em" }}>
              <Col>
                <h2 style={{ color: "white" }}>
                  Ready to scale and build something amazing?
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="white-btns"
                  onClick={() => {
                    window
                      .open(
                        "mailto:webleads@teamwawe.com?subject=Looking to Book a Call!",
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  Book a call!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.imageAndTextContainer}>
          <div className={styles.imageContainer}>
            <img src="/assets/team-work.webp" width="300px" />
          </div>
          <div className={styles.text}>
            We don't usually like to brag, but our team is stacked! The list of
            services we offer is a high level overview of what we do. Our team
            has experience doing a lot more than what is listed. We find our
            clients have the best success in determining if we are the right fit
            over a quick video call where we look to understand the challenges
            and needs of your team.
          </div>
        </div>
        <Row style={{ padding: "2em" }}>
          <Col align={ALIGNMENT.center}>
            <Button
              className="black-btns"
              onClick={() => {
                window
                  .open(
                    "mailto:webleads@teamwawe.com?subject=Looking to Book a Call!",
                    "_blank"
                  )
                  .focus();
              }}
            >
              Book a call!
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ backgroundColor: "black", padding: "2em" }}
            align={ALIGNMENT.center}
          >
            <Row style={{ paddingBottom: "1em" }}>
              <Col>
                <h2 style={{ color: "white" }}>Why Choose Us</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  className={styles.typography2}
                  style={{ color: "white", paddingBottom: "1em" }}
                >
                  At WaWe, we don't just build software; we engineer solutions
                  that empower growth. Our team stands apart by blending
                  top-tier product engineering with an ethos of self-sufficiency
                  and a commitment to transparent communication. Here's why you
                  should partner with us:
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={[styles.cardCol]}>
                <Card className={styles.card}>
                  <div align={ALIGNMENT.center}>
                    <h2>Expert Product Engineering & Execution</h2>
                    <p>
                      <FontAwesomeIcon icon={faHome} size={"1x"} />
                      <br />
                      <strong>
                        Industry-Specific Expertise Across the Board
                      </strong>
                      <br />
                      Our product engineers are far more than coders; they are
                      maestros in tailoring software to the pulse of your
                      industry. With extensive experience in real estate,
                      logistics, home automation, smart security, legaltech,
                      e-learning, dating, and marketing, we build robust and
                      intelligent platforms. We ensure your technology is not
                      only state-of-the-art but also scalable and future-proof,
                      setting the stage for sustained excellence in your sector.
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faFighterJet} size={"1x"} />
                      <br />
                      <strong>
                        Rapid Deployment with a Clean, Reusable Approach
                      </strong>
                      <br />
                      Your time is invaluable, and we respect that with one of
                      the industry’s quickest turnaround times from zero to
                      launch. Our commitment is to build clean, reusable apps
                      using the latest technologies and standards, ensuring a
                      swift transition from concept to execution, all while
                      maintaining the highest quality.
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faShieldAlt} size={"1x"} />
                      <br />
                      <strong>Continuous Delivery & Support</strong>
                      <br />
                      Our dedicated DevOps and infrastructure services
                      streamline software implementation, provide ongoing bug
                      support, and ensure that your product is capable of
                      evolving with your business needs.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className={[styles.cardCol]}>
                <Card className={styles.chooseUsCard}>
                  <div align={ALIGNMENT.center}>
                    <h2>Client-Centric Approach & Assurance</h2>
                    <p>
                      <FontAwesomeIcon icon={faCertificate} size={"1x"} />
                      <br />
                      <strong>Risk-Free Estimates & Consultations</strong>
                      <br />
                      Dive into your project with us without any upfront
                      investment. Our free work estimates and product
                      discussions are designed to assure you of our commitment
                      before you take the first step.
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faTachometerAlt} size={"1x"} />
                      <br />
                      <strong>Quality Assurance & Quick Response</strong>
                      <br />
                      Our rigorous Quality Assurance processes ensure that we
                      deliver only the best. Paired with our commitment to a
                      quick response for any comments or questions, we promise a
                      seamless and responsive partnership experience.
                    </p>
                  </div>
                </Card>
              </Col>
              <Col className={[styles.cardCol]}>
                <Card className={styles.chooseUsCard}>
                  <div align={ALIGNMENT.center}>
                    <h2>Unmatched Project Management & Transparency</h2>
                    <p>
                      <FontAwesomeIcon icon={faComments} size={"1x"} />
                      <br />
                      <strong>Self-Driven Excellence</strong>
                      <br />
                      Our culture is built on self-management and initiative.
                      With a team that operates autonomously and makes informed
                      decisions efficiently, we ensure your project advances
                      with minimal need for oversight, staying on the path to
                      success.
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faRobot} size={"1x"} />
                      <br />
                      <strong>Transparent Autonomy</strong>
                      <br />
                      Every fine-grained update on our actively maintained
                      Trello board is more than just a status report; it’s proof
                      of our proactive and self-sufficient approach. You can
                      rest assured that your project is progressing even beyond
                      the checkpoints, with our team anticipating and addressing
                      needs ahead of time.
                    </p>
                  </div>
                </Card>
              </Col>
              <Col className={[styles.cardCol]}>
                <Card className={styles.chooseUsCard}>
                  <div align={ALIGNMENT.center}>
                    <h2>Tailored Strategy & Design</h2>
                    <p>
                      <FontAwesomeIcon icon={faDesktop} size={"1x"} />
                      <br />
                      <strong>Bespoke Product Strategy</strong>
                      <br />
                      Every project begins with a meticulously crafted plan. Our
                      experts provide a finely tuned scope of work, along with
                      creating Figma designs that align with your vision and set
                      the foundation for a high-impact product.
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPuzzlePiece} size={"1x"} />
                      <br />
                      <strong>
                        Custom Tech Solutions for Unique Challenges
                      </strong>
                      <br />
                      We listen, we consult at no cost, and we strategize. Then
                      we craft tech solutions that are as unique as your
                      project, ensuring they are perfectly suited to meet and
                      exceed your specific requirements.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col style={{ padding: "2em" }}>
                <h2 style={{ color: "white" }}>The WaWe Guarantee</h2>
                <div className={styles.typography2} style={{ color: "white" }}>
                  Choosing WaWe means selecting a partner who is invested in
                  your success. Our deeply experienced team not only prioritizes
                  your project but does so with a passion for innovation,
                  excellence, and rapid execution. If you’re ready for a premier
                  software consulting experience that justifies your investment,
                  it's time to connect and bring your vision to fruition.
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="white-btns"
                  onClick={() => {
                    window
                      .open(
                        "mailto:webleads@teamwawe.com?subject=Looking to work with you!",
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  Request Estimate
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};
