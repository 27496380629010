import React from 'react';
import { StyledLink } from 'baseui/link';
import { TwoColumnRow } from '../components/two-column-row';
import { useWindowSize } from '../hooks/useWindowSize';
import { Row, Col } from 'react-bootstrap'

import {
  faCloud,
  faCodeBranch,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

const services = [
  {
    heading: 'Cloud Architecture',
    body: 'Our team can help with designing, implementing, testing, and monitoring infrastructure and applications in the cloud. We primarily focus on AWS and Azure. Our architects specialize in cloud native solutions that are optimized for performance and reliability. They can go as far as reviewing your existing system architecture to identify opportunities to save on compute and storage costs. Our team of engineers also have experience writing infrastructure as code using Terraform and Cloud Formation.',
    icon: faCloud,
  },
  {
    heading: 'Fullstack Development',
    body: 'Our team contains both front end and back end engineers. Our front end engineers specialize in React.js and Angular, user-experience (UX) and user-interface design (UI). Our backend engineers follow best practices around security, design, and testing when implementing RESTful APIs. Backend technologies we specialize in are C#, Java, and Node.js.',
    icon: faCodeBranch,
  },
  {
    heading: 'Team Building',
    body: 'Building and retaining high executing teams is tough. We offer advice and services to acquiring new talent, like candidate screening and interview process refinement. Once you have great talent hired you want to make sure you retain those individuals that propel your team to the next level. WaWe can advise our clients on how to keep their compensation packages competitive with the market, as well as, ensure your team\'s health is at sustainable levels.',
    icon: faUsers,
  }
];

export const ServicesPage = () => {
  const size = useWindowSize();

  return (
      <Row>
        <Col lg="12">
          <h1>Services</h1>
          <p>
            We don't usually like to brag, but our team is stacked! Below is a list of services we offer at a high level, but our team has experience doing a lot more than what is listed.
            We find our clients have the best success in determining if we are the right fit over a quick video call where we look to understand the challenges and needs of your team.
          </p>
          <br />
          <br/>
          {services.map((x, i) => {
            const isOdd = i % 2 === 0;
            return (
              <React.Fragment>
                {isOdd && (
                  <TwoColumnRow
                    heading={x.heading}
                    body={x.body}
                    icon={x.icon}
                  />
                )}
                {!isOdd && (
                  <TwoColumnRow
                    heading={x.heading}
                    body={x.body}
                    icon={x.icon}
                    flip={size.width < 1135 ? false : true}
                  />
                )}
                {i !== services.length - 1 && <React.Fragment><br /><br/></React.Fragment>}
              </React.Fragment>
            );
          })}
          <p>
            Work with us:{' '}
            {/* TODO remove StyledLink */}
            <StyledLink href="mailto:sales@teamwawe.com?subject=Sales Inquire">
              sales@teamwawe.com
            </StyledLink>
          </p>
        </Col>
      </Row>
  );
};
