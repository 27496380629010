import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { ALIGNMENT } from "baseui/layout-grid";

import styles from "./header.module.scss";

export const Header = () => {
  return (
    <Container className={styles.headerContainer}>
      <Row>
        <Col align={ALIGNMENT.start}>
          <img src="/assets/wawe-logo.png" />
        </Col>
      </Row>
    </Container>
  );
};
