import * as React from "react";
import {
  faLinkedin,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import IconLink from "./icon-link";
import { Row, Col } from "react-bootstrap";
import { ALIGNMENT } from "baseui/layout-grid";

import styles from "./footer.module.scss";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Row style={{ paddingBottom: "2em" }}>
      <Col>
        <Row align={ALIGNMENT.center}>
          <Col>
            <IconLink
              className="social-icons"
              href="https://www.instagram.com/teamwawe/"
              icon={faInstagram}
              size={"2x"}
            />
          </Col>
          <Col>
            <IconLink
              className="social-icons"
              href="https://facebook.com/teamwawe"
              icon={faFacebook}
              size={"2x"}
            />
          </Col>
          <Col>
            <IconLink
              className="social-icons"
              href="https://www.linkedin.com/company/teamwawe"
              icon={faLinkedin}
              size={"2x"}
            />
          </Col>
        </Row>
        <Row align={ALIGNMENT.center}>
          <Col className={styles.logo}>
            <img src="/assets/wawe-logo.png" />
          </Col>
        </Row>
        <Row align={ALIGNMENT.center}>
          <Col><a className="legal-links" href="/privacy">PRIVACY POLICY</a> | <a className="legal-links" href="/terms">TERMS AND CONDITIONS</a></Col>
        </Row>
      </Col>
    </Row>
  );
};
